import React from 'react';

const Header = () => {
    return (
        <header>
            <h1>Hal Scoon</h1>
        </header>
    );
}

export default Header;
