// src/Projects.js
import React from 'react';
import ProjectPanel from './ProjectPanel';

const Projects = () => {
    const projects = [
        {
            title: "Comprehensive Orthopedics",
            description: "Dynamic form developed to replace current printed MRI paperwork.",
            link: "https://comprehensive-orthopedics.web.app/",
            technologies: ["HTML", "CSS", "JavaScript", "React", ]
        },
        {
            title: "Sultana Disaster Museum",
            description: "Database containing information on the passengers aboard the Sultana steamboat.",
            link: "https://sultana-7f06a.web.app/search",
            technologies: ["HTML", "CSS", "JavaScript", "Vue.js", ]
        },
        {
            title: "CareFinder",
            description: "Class project in which user can search for hospitals in a database using certain criteria.",
            link: "https://carefinder-2024.web.app/",
            technologies: ["HTML", "CSS", "JavaScript", "Node.js", ]
        },
        {
            title: "Tilorum",
            description: "WIP. Informational website on a D&D campaign. Users can view various content and submit an NPC concept to a database.",
            link: "https://tilorum-a01b9.web.app/",
            technologies: ["HTML", "CSS", "JavaScript", "React",]
        },
    ];

    return (
        <section id="projects" className="projects-grid">
            {projects.map((project, index) => (
                <ProjectPanel
                    key={index}
                    title={project.title}
                    description={project.description}
                    link={project.link}
                    technologies={project.technologies}
                />
            ))}
        </section>
    );
}

export default Projects;
